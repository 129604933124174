import { BaseError } from '../../errors.js';
export class InvalidAbiItemError extends BaseError {
  constructor({
    signature
  }) {
    super('Failed to parse ABI item.', {
      details: `parseAbiItem(${JSON.stringify(signature, null, 2)})`,
      docsPath: '/api/human#parseabiitem-1'
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidAbiItemError'
    });
  }
}
export class UnknownTypeError extends BaseError {
  constructor({
    type
  }) {
    super('Unknown type.', {
      metaMessages: [`Type "${type}" is not a valid ABI type. Perhaps you forgot to include a struct signature?`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'UnknownTypeError'
    });
  }
}
export class UnknownSolidityTypeError extends BaseError {
  constructor({
    type
  }) {
    super('Unknown type.', {
      metaMessages: [`Type "${type}" is not a valid ABI type.`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'UnknownSolidityTypeError'
    });
  }
}
