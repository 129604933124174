export function concat(values) {
  if (typeof values[0] === 'string') return concatHex(values);
  return concatBytes(values);
}
export function concatBytes(values) {
  let length = 0;
  for (const arr of values) {
    length += arr.length;
  }
  const result = new Uint8Array(length);
  let offset = 0;
  for (const arr of values) {
    result.set(arr, offset);
    offset += arr.length;
  }
  return result;
}
export function concatHex(values) {
  return `0x${values.reduce((acc, x) => acc + x.replace('0x', ''), '')}`;
}
