import { SizeExceedsPaddingSizeError } from '../../errors/data.js';
export function pad(hexOrBytes, {
  dir,
  size = 32
} = {}) {
  if (typeof hexOrBytes === 'string') return padHex(hexOrBytes, {
    dir,
    size
  });
  return padBytes(hexOrBytes, {
    dir,
    size
  });
}
export function padHex(hex_, {
  dir,
  size = 32
} = {}) {
  if (size === null) return hex_;
  const hex = hex_.replace('0x', '');
  if (hex.length > size * 2) throw new SizeExceedsPaddingSizeError({
    size: Math.ceil(hex.length / 2),
    targetSize: size,
    type: 'hex'
  });
  return `0x${hex[dir === 'right' ? 'padEnd' : 'padStart'](size * 2, '0')}`;
}
export function padBytes(bytes, {
  dir,
  size = 32
} = {}) {
  if (size === null) return bytes;
  if (bytes.length > size) throw new SizeExceedsPaddingSizeError({
    size: bytes.length,
    targetSize: size,
    type: 'bytes'
  });
  const paddedBytes = new Uint8Array(size);
  for (let i = 0; i < size; i++) {
    const padEnd = dir === 'right';
    paddedBytes[padEnd ? i : size - i - 1] = bytes[padEnd ? i : bytes.length - i - 1];
  }
  return paddedBytes;
}
