import { BaseError } from './base.js';
export class NegativeOffsetError extends BaseError {
  constructor({
    offset
  }) {
    super(`Offset \`${offset}\` cannot be negative.`, {
      name: 'NegativeOffsetError'
    });
  }
}
export class PositionOutOfBoundsError extends BaseError {
  constructor({
    length,
    position
  }) {
    super(`Position \`${position}\` is out of bounds (\`0 < position < ${length}\`).`, {
      name: 'PositionOutOfBoundsError'
    });
  }
}
export class RecursiveReadLimitExceededError extends BaseError {
  constructor({
    count,
    limit
  }) {
    super(`Recursive read limit of \`${limit}\` exceeded (recursive read count: \`${count}\`).`, {
      name: 'RecursiveReadLimitExceededError'
    });
  }
}
