import { InvalidDefinitionTypeError } from '../../errors/abi.js';
export function formatAbiItem(abiItem, {
  includeName = false
} = {}) {
  if (abiItem.type !== 'function' && abiItem.type !== 'event' && abiItem.type !== 'error') throw new InvalidDefinitionTypeError(abiItem.type);
  return `${abiItem.name}(${formatAbiParams(abiItem.inputs, {
    includeName
  })})`;
}
export function formatAbiParams(params, {
  includeName = false
} = {}) {
  if (!params) return '';
  return params.map(param => formatAbiParam(param, {
    includeName
  })).join(includeName ? ', ' : ',');
}
function formatAbiParam(param, {
  includeName
}) {
  if (param.type.startsWith('tuple')) {
    return `(${formatAbiParams(param.components, {
      includeName
    })})${param.type.slice('tuple'.length)}`;
  }
  return param.type + (includeName && param.name ? ` ${param.name}` : '');
}
