import { execTyped } from '../regex.js';
// https://regexr.com/7f7rv
const tupleRegex = /^tuple(?<array>(\[(\d*)\])*)$/;
/**
 * Formats {@link AbiParameter} to human-readable ABI parameter.
 *
 * @param abiParameter - ABI parameter
 * @returns Human-readable ABI parameter
 *
 * @example
 * const result = formatAbiParameter({ type: 'address', name: 'from' })
 * //    ^? const result: 'address from'
 */
export function formatAbiParameter(abiParameter) {
  let type = abiParameter.type;
  if (tupleRegex.test(abiParameter.type) && 'components' in abiParameter) {
    type = '(';
    const length = abiParameter.components.length;
    for (let i = 0; i < length; i++) {
      const component = abiParameter.components[i];
      type += formatAbiParameter(component);
      if (i < length - 1) type += ', ';
    }
    const result = execTyped(tupleRegex, abiParameter.type);
    type += `)${result?.array ?? ''}`;
    return formatAbiParameter({
      ...abiParameter,
      type
    });
  }
  // Add `indexed` to type if in `abiParameter`
  if ('indexed' in abiParameter && abiParameter.indexed) type = `${type} indexed`;
  // Return human-readable ABI parameter
  if (abiParameter.name) return `${type} ${abiParameter.name}`;
  return type;
}
