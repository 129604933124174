import { stringify } from '../stringify.js';
export function formatAbiItemWithArgs({
  abiItem,
  args,
  includeFunctionName = true,
  includeName = false
}) {
  if (!('name' in abiItem)) return;
  if (!('inputs' in abiItem)) return;
  if (!abiItem.inputs) return;
  return `${includeFunctionName ? abiItem.name : ''}(${abiItem.inputs.map((input, i) => `${includeName && input.name ? `${input.name}: ` : ''}${typeof args[i] === 'object' ? stringify(args[i]) : args[i]}`).join(', ')})`;
}
