import { ChainDoesNotSupportContract } from '../../errors/chain.js';
export function getChainContractAddress({
  blockNumber,
  chain,
  contract: name
}) {
  const contract = chain?.contracts?.[name];
  if (!contract) throw new ChainDoesNotSupportContract({
    chain,
    contract: {
      name
    }
  });
  if (blockNumber && contract.blockCreated && contract.blockCreated > blockNumber) throw new ChainDoesNotSupportContract({
    blockNumber,
    chain,
    contract: {
      name,
      blockCreated: contract.blockCreated
    }
  });
  return contract.address;
}
