import { BaseError } from '../../errors.js';
export class InvalidParenthesisError extends BaseError {
  constructor({
    current,
    depth
  }) {
    super('Unbalanced parentheses.', {
      metaMessages: [`"${current.trim()}" has too many ${depth > 0 ? 'opening' : 'closing'} parentheses.`],
      details: `Depth "${depth}"`
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidParenthesisError'
    });
  }
}
