import { BaseError } from '../../errors.js';
export class InvalidAbiParameterError extends BaseError {
  constructor({
    param
  }) {
    super('Failed to parse ABI parameter.', {
      details: `parseAbiParameter(${JSON.stringify(param, null, 2)})`,
      docsPath: '/api/human#parseabiparameter-1'
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidAbiParameterError'
    });
  }
}
export class InvalidAbiParametersError extends BaseError {
  constructor({
    params
  }) {
    super('Failed to parse ABI parameters.', {
      details: `parseAbiParameters(${JSON.stringify(params, null, 2)})`,
      docsPath: '/api/human#parseabiparameters-1'
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidAbiParametersError'
    });
  }
}
export class InvalidParameterError extends BaseError {
  constructor({
    param
  }) {
    super('Invalid ABI parameter.', {
      details: param
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidParameterError'
    });
  }
}
export class SolidityProtectedKeywordError extends BaseError {
  constructor({
    param,
    name
  }) {
    super('Invalid ABI parameter.', {
      details: param,
      metaMessages: [`"${name}" is a protected Solidity keyword. More info: https://docs.soliditylang.org/en/latest/cheatsheet.html`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'SolidityProtectedKeywordError'
    });
  }
}
export class InvalidModifierError extends BaseError {
  constructor({
    param,
    type,
    modifier
  }) {
    super('Invalid ABI parameter.', {
      details: param,
      metaMessages: [`Modifier "${modifier}" not allowed${type ? ` in "${type}" type` : ''}.`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidModifierError'
    });
  }
}
export class InvalidFunctionModifierError extends BaseError {
  constructor({
    param,
    type,
    modifier
  }) {
    super('Invalid ABI parameter.', {
      details: param,
      metaMessages: [`Modifier "${modifier}" not allowed${type ? ` in "${type}" type` : ''}.`, `Data location can only be specified for array, struct, or mapping types, but "${modifier}" was given.`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidFunctionModifierError'
    });
  }
}
export class InvalidAbiTypeParameterError extends BaseError {
  constructor({
    abiParameter
  }) {
    super('Invalid ABI parameter.', {
      details: JSON.stringify(abiParameter, null, 2),
      metaMessages: ['ABI parameter type is invalid.']
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidAbiTypeParameterError'
    });
  }
}
