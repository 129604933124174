import { BaseError } from './base.js';
export class SliceOffsetOutOfBoundsError extends BaseError {
  constructor({
    offset,
    position,
    size
  }) {
    super(`Slice ${position === 'start' ? 'starting' : 'ending'} at offset "${offset}" is out-of-bounds (size: ${size}).`, {
      name: 'SliceOffsetOutOfBoundsError'
    });
  }
}
export class SizeExceedsPaddingSizeError extends BaseError {
  constructor({
    size,
    targetSize,
    type
  }) {
    super(`${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()} size (${size}) exceeds padding size (${targetSize}).`, {
      name: 'SizeExceedsPaddingSizeError'
    });
  }
}
export class InvalidBytesLengthError extends BaseError {
  constructor({
    size,
    targetSize,
    type
  }) {
    super(`${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()} is expected to be ${targetSize} ${type} long, but is ${size} ${type} long.`, {
      name: 'InvalidBytesLengthError'
    });
  }
}
