import { formatAbiParameters } from './formatAbiParameters.js';
/**
 * Formats ABI item (e.g. error, event, function) into human-readable ABI item
 *
 * @param abiItem - ABI item
 * @returns Human-readable ABI item
 */
export function formatAbiItem(abiItem) {
  if (abiItem.type === 'function') return `function ${abiItem.name}(${formatAbiParameters(abiItem.inputs)})${abiItem.stateMutability && abiItem.stateMutability !== 'nonpayable' ? ` ${abiItem.stateMutability}` : ''}${abiItem.outputs.length ? ` returns (${formatAbiParameters(abiItem.outputs)})` : ''}`;
  if (abiItem.type === 'event') return `event ${abiItem.name}(${formatAbiParameters(abiItem.inputs)})`;
  if (abiItem.type === 'error') return `error ${abiItem.name}(${formatAbiParameters(abiItem.inputs)})`;
  if (abiItem.type === 'constructor') return `constructor(${formatAbiParameters(abiItem.inputs)})${abiItem.stateMutability === 'payable' ? ' payable' : ''}`;
  if (abiItem.type === 'fallback') return 'fallback()';
  return 'receive() external payable';
}
