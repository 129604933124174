import { BaseError } from '../../errors.js';
export class CircularReferenceError extends BaseError {
  constructor({
    type
  }) {
    super('Circular reference detected.', {
      metaMessages: [`Struct "${type}" is a circular reference.`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'CircularReferenceError'
    });
  }
}
