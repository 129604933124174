import { SliceOffsetOutOfBoundsError } from '../../errors/data.js';
import { isHex } from './isHex.js';
import { size } from './size.js';
/**
 * @description Returns a section of the hex or byte array given a start/end bytes offset.
 *
 * @param value The hex or byte array to slice.
 * @param start The start offset (in bytes).
 * @param end The end offset (in bytes).
 */
export function slice(value, start, end, {
  strict
} = {}) {
  if (isHex(value, {
    strict: false
  })) return sliceHex(value, start, end, {
    strict
  });
  return sliceBytes(value, start, end, {
    strict
  });
}
function assertStartOffset(value, start) {
  if (typeof start === 'number' && start > 0 && start > size(value) - 1) throw new SliceOffsetOutOfBoundsError({
    offset: start,
    position: 'start',
    size: size(value)
  });
}
function assertEndOffset(value, start, end) {
  if (typeof start === 'number' && typeof end === 'number' && size(value) !== end - start) {
    throw new SliceOffsetOutOfBoundsError({
      offset: end,
      position: 'end',
      size: size(value)
    });
  }
}
/**
 * @description Returns a section of the byte array given a start/end bytes offset.
 *
 * @param value The byte array to slice.
 * @param start The start offset (in bytes).
 * @param end The end offset (in bytes).
 */
export function sliceBytes(value_, start, end, {
  strict
} = {}) {
  assertStartOffset(value_, start);
  const value = value_.slice(start, end);
  if (strict) assertEndOffset(value, start, end);
  return value;
}
/**
 * @description Returns a section of the hex value given a start/end bytes offset.
 *
 * @param value The hex value to slice.
 * @param start The start offset (in bytes).
 * @param end The end offset (in bytes).
 */
export function sliceHex(value_, start, end, {
  strict
} = {}) {
  assertStartOffset(value_, start);
  const value = `0x${value_.replace('0x', '').slice((start ?? 0) * 2, (end ?? value_.length) * 2)}`;
  if (strict) assertEndOffset(value, start, end);
  return value;
}
