import { BaseError } from '../../errors.js';
export class InvalidSignatureError extends BaseError {
  constructor({
    signature,
    type
  }) {
    super(`Invalid ${type} signature.`, {
      details: signature
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidSignatureError'
    });
  }
}
export class UnknownSignatureError extends BaseError {
  constructor({
    signature
  }) {
    super('Unknown signature.', {
      details: signature
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'UnknownSignatureError'
    });
  }
}
export class InvalidStructSignatureError extends BaseError {
  constructor({
    signature
  }) {
    super('Invalid struct signature.', {
      details: signature,
      metaMessages: ['No properties exist.']
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidStructSignatureError'
    });
  }
}
