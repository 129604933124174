import { BaseError } from './base.js';
export class IntegerOutOfRangeError extends BaseError {
  constructor({
    max,
    min,
    signed,
    size,
    value
  }) {
    super(`Number "${value}" is not in safe ${size ? `${size * 8}-bit ${signed ? 'signed' : 'unsigned'} ` : ''}integer range ${max ? `(${min} to ${max})` : `(above ${min})`}`, {
      name: 'IntegerOutOfRangeError'
    });
  }
}
export class InvalidBytesBooleanError extends BaseError {
  constructor(bytes) {
    super(`Bytes value "${bytes}" is not a valid boolean. The bytes array must contain a single byte of either a 0 or 1 value.`, {
      name: 'InvalidBytesBooleanError'
    });
  }
}
export class InvalidHexBooleanError extends BaseError {
  constructor(hex) {
    super(`Hex value "${hex}" is not a valid boolean. The hex value must be "0x0" (false) or "0x1" (true).`, {
      name: 'InvalidHexBooleanError'
    });
  }
}
export class InvalidHexValueError extends BaseError {
  constructor(value) {
    super(`Hex value "${value}" is an odd length (${value.length}). It must be an even length.`, {
      name: 'InvalidHexValueError'
    });
  }
}
export class SizeOverflowError extends BaseError {
  constructor({
    givenSize,
    maxSize
  }) {
    super(`Size cannot exceed ${maxSize} bytes. Given size: ${givenSize} bytes.`, {
      name: 'SizeOverflowError'
    });
  }
}
